export const homeOwnershipImage = homeOwnershipOption => {
  if (typeof homeOwnershipOption !== "undefined" && homeOwnershipOption !== null) {
    return "/images/home_ownership/" + homeOwnershipOption.toString().padStart(3, "0") + ".png"
  } else {
  //  console.log("no homeOwnershipOption", homeOwnershipOption)
  }
}

export const providerImage = providerId => {
  if (typeof providerId !== "undefined" && providerId !== null) {
    return "/images/provider/" + providerId.toString().padStart(5, "0") + ".png"
  } else {
  //  console.log("no providerId", providerId)
  }
}

export const rewardProgramImage = rewardProgramId => {
  if (typeof rewardProgramId !== "undefined" && rewardProgramId !== null) {
    return "/images/reward_program/" + rewardProgramId.toString().padStart(4, "0") + "_REWARDS.png"
  } else {
  //  console.log("no rewardProgramId", rewardProgramId)
  }
}

export const cardImage = (cardId, num = 1) => {
  if (typeof cardId !== "undefined" && cardId !== null) {
    return "/images/cards/" + cardId.toString().padStart(10, "0") + "_" + num.toString() + ".png"
  } else {
  //  console.log("no cardId", cardId)
  }
}

export const debitCardImage = (productId, num=null) => {
  if (productId !== null) {
    return "/images/debit-cards/" + productId + (num === null ? "" : ("_" + num.toString())) + ".png"
  } else {
  //  console.log("no card image for productId", productId)
  }
}

export const cardSchemeImage = schemeId => {
  if (typeof schemeId !== "undefined" && schemeId !== null) {
    return "/images/schemes/" + schemeId.toString().padStart(3, "0") + ".png"
  } else {
  //  console.log("no schemeId", schemeId)
  }
}
