import { navigate } from "@reach/router"
import React, { useEffect } from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { acceptOffer } from "@sog/sdk"
import AppManager from "services/app-manager"
import { providerImage } from "./utils"

const Redirect = props => {
  if (typeof window === `undefined`) return <></>

  var searchParams = new URLSearchParams(props.location.search)
  const pid = searchParams.get("pid")
  const pname = searchParams.get("pname")
  const offerType = searchParams.get("t")
  const offerId = searchParams.get("o")
  const sidBase64 = searchParams.get("sid")
  const webpageBase64 = searchParams.get("webpage")

  if (typeof window !== `undefined`) window.Buffer = window.Buffer || require("buffer").Buffer
  const surveyId = typeof window !== `undefined` ? Buffer.from(sidBase64, "base64").toString("ascii") : ""
  const webpageUrl = typeof window !== `undefined` ? Buffer.from(webpageBase64, "base64").toString("ascii") : ""

  useEffect(() => {
    setTimeout(async () => {
      const redirectUrl = await acceptOffer(offerId, offerType, webpageUrl, surveyId)
      navigate(redirectUrl)
    }, 3000)
    return () => {}
  }, [offerId, offerType, webpageUrl, surveyId])
  return (
    <div className="page-container">
      <div className="survey-container">
        <div style={{ color: "#202020", textAlign: "center", height: "24px", display: "block", lineHeight: "24px", fontSize: "22px", fontFamily: "Arboria-Bold, sans-serif" }}>Redirecting you</div>
        <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <div style={{ marginBottom: "50px", marginTop: "50px", width: "100%", display: "flex", alignItems: "center", height: "50px", justifyContent: "center" }}>
            <LazyLoadImage src="/images/logo.png" style={{width: "70px", height: "50px"}} alt="" />

            <div style={{ maxWidth: "30%", display: "flex", justifyContent: "center", marginLeft: "5%", marginRight: " 5%" }}>
              <LazyLoadImage src="/images/icon_double_arrow.png" style={{width: "40px", height: "40px"}} alt="" />
            </div>

            {pid && <LazyLoadImage src={providerImage(pid)} style={{width: "70px", height: "50px"}} alt="" />}
          </div>
        </div>
        <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <div style={{ color: "#4D4D4D", width: "90%", textAlign: "center", height: "18px", display: "block", lineHeight: "20px", fontSize: "16px", fontFamily: "Arboria-book, sans-serif" }}>{"You are now being redirected to the " + pname + " website and application."}</div>
        </div>
      </div>
    </div>
  )
}
export default Redirect
